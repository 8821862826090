<template>
  <div>
    <v-card>
      <v-card-title> 契約科目 </v-card-title>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'contractItem-create', params: { query: this.$route.query } }"
          >
            <span>NEW</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="contractItemsList"
        :search="searchQuery"
        sort-by="ci_id"
        class="elevation-1"
      >
        <template v-slot:top>
        </template>
        <!-- <template v-slot:item.actions="{ item }"> -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icon.mdiPencil }}
              </v-icon>
            </div>
            <Delete
              :id="item.ci_id"
              class="py-2 pl-1"
              :icon="icon.mdiDelete"
              @deleted="loadData"
              @cancel="loadData"
            ></Delete>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import {
  mdiPencil, mdiDelete, mdiExportVariant,
} from '@mdi/js'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import Delete from './contractItemDelete.vue'

export default {
  components: {
    Delete,
  },
  data: () => ({
    showDialog: false,
    icon: {
      mdiPencil, mdiDelete, mdiExportVariant,
    },
    searchQuery: '',
    headers: [
      {
        text: 'ID',
        align: 'left',
        sortable: true,
        value: 'ci_id',
        width: '10%',
        fixed: true,
      },
      {
        text: '契約科目名',
        value: 'ci_name',
        sortable: true,
        align: 'left',
        width: '14%',
        fixed: true,
      },
      {
        text: '契約科目内容(日本語)',
        value: 'ci_content_ja',
        sortable: true,
        align: 'left',
        width: '22%',
        fixed: true,
      },
      {
        text: '契約科目内容(中国語)',
        value: 'ci_content_cn',
        sortable: true,
        align: 'left',
        width: '22%',
        fixed: true,
      },
      {
        text: '契約科目内容(英語)',
        value: 'ci_content_en',
        sortable: true,
        align: 'left',
        width: '22%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '10%',
        fixed: true,
      },
    ],

  }),

  computed: {
    ...mapState('contractStore', ['contractItemsList']),
  },
  watch: {
  },

  created() {
    this.loadData()
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('contractStore', ['loadContractItems']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadContractItems().then(() => {
        this.setOverlayStatus(false)
      })
    },
    editItem(item) {
      this.$router.push({
        name: 'contractItem-edit',
        params: {
          id: item.ci_id, query: this.$route.query,
        },
      })
    },

    // ...mapMutations('countryStore', ['filterCountries']),

  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
