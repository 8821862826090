<template>
  <div justify="center">
    <v-icon small @click.stop="openForm">
      {{ icon }}
    </v-icon>
    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5" style="color: red">削除</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-if="apierror.status == 'error'">
              <div v-for="msg of apierror.messages" :key="msg">
                <v-row class="ml-6 mb-3 ma-3">
                  <span style="color: red">* {{ msg }} </span>
                </v-row>
              </div>
            </template>
            <v-row>
              <v-col cols="4">
                <span>契約科目名</span>
              </v-col>
              <v-col cols="8">
                {{ selectedContractItem.ci_name }}
              </v-col>
              <v-col cols="4">
                <span>契約科目内容（日本語）</span>
              </v-col>
              <v-col cols="8">
                {{ selectedContractItem.ci_content_ja }}
              </v-col>
              <v-col cols="4">
                <span>契約科目内容（中国語）</span>
              </v-col>
              <v-col cols="8">
                {{ selectedContractItem.ci_content_cn }}
              </v-col>
              <v-col cols="4">
                <span>契約科目内容（英語）</span>
              </v-col>
              <v-col cols="8">
                {{ selectedContractItem.ci_content_en }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :loading="submitStatus" @click="deleteItem(selectedContractItem)">
            YES
          </v-btn>
          <v-btn color="error" @click="closeForm()">
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'

export default {
  props: ['icon', 'id'],
  data: () => ({
    url: null,
    submitStatus: false,
    dialog: false,
    item: {
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  computed: {
    ...mapState('contractStore', ['selectedContractItem']),
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    deleteItem(selectedContractItem) {
      console.log(selectedContractItem)
      this.apierror.messages = []
      this.submitStatus = true
      this.deleteContractItem(selectedContractItem.ci_id)
        .then(() => {
          this.dialog = false
          this.$emit('deleted')
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.submitStatus = false
        })
    },
    openForm() {
      this.setOverlayStatus(true)
      this.loadContractItem(this.id)
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.dialog = true
          this.setOverlayStatus(false)
        })
    },
    closeForm() {
      this.$emit('cancel')
      this.apierror.messages = []
      this.dialog = false
      this.item = {
      }
    },
    ...mapActions('contractStore', ['deleteContractItem', 'loadContractItem']),
  },
}
</script>
<style scoped>
/* .theme--light.v-label--is-disabled {
  color: rgba(241, 104, 12, 0.87)  !important;
}
.theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: rgba(15, 218, 140, 0.87)  !important;
} */
</style>
